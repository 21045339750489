import React from "react";
import Connection from "../../Modules/Connection";

const ConnectionScreen = () => {
  return (
    <>
      <Connection />
    </>
  );
};

export default ConnectionScreen;
