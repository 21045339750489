import React from "react";
import Verticals from "../../Modules/Verticals";

const VerticalsScreen = () => {
  return (
    <>
      <Verticals />
    </>
  );
};

export default VerticalsScreen;
