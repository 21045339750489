import React from "react";
import Verify from "../../Modules/Auth/Verify";

const VerifyScreen = () => {
  return (
    <>
      <Verify />
    </>
  );
};

export default VerifyScreen;
