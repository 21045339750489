import React from "react";
import FollowUnfollow from "../../Modules/FollowUnfollow";

const FollowUnfollowScreen = () => {
  return (
    <>
      <FollowUnfollow />
    </>
  );
};

export default FollowUnfollowScreen;
