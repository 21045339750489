import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Button, Row, Col, Table, Card, Input, Tabs, Form, Image } from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import MainLayout from "../../../Layout/MainLayout";
import { getApi } from "../../../services/getApi";
import WithAuth from "../../../Middleware/WithAuth";
import { logout } from "../../../redux/actions/common";
import OnlyAdmin from "../../../Middleware/OnlyAdmin";
import { openInNewTab } from "../../../util/helper";

const VerticalDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { paramId } = useParams();
  const user = useSelector((state) => state.app.user);
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const usersRef = useRef(allUsers);
  const [verticalData, setVerticalData] = useState(null);

  useEffect(() => {
    getApi({
      cbSuccess: (response) => {
        if (response) {
          console.log("response w", response);
          setVerticalData(response);
        }
        setIsLoading(false);
      },
      cbFailure: (err) => {
        setIsLoading(false);
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: `verticals/${paramId}`,
      value: null,
    });
  }, []);

  const renderHeader = () => {
    return (
      <>
        {" "}
        <Button
          type="primary"
          style={{
            background: "transparent",
            color: "white",
            marginRight: "8px",
          }}
          onClick={() => [history.goBack()]}
        >
          <ArrowLeftOutlined />
        </Button>
        Vertical Detail
      </>
    );
  };

  return (
    <>
      <MainLayout>
        <WithAuth>
          <Row>
            <Col xs={24} className="mt-20">
              <Card title={renderHeader()}>
                <Row gutter={8}>
                  <Col xs={12}>
                    <Col>
                      <strong>Vertical Name: </strong>
                      {verticalData?.name}
                    </Col>
                    <Col>
                      <strong>created At: </strong>
                      {moment(verticalData?.createdAt).format(
                        "DD-MM-YYYY hh:mm:ss"
                      )}
                    </Col>
                  </Col>
                  <Col xs={12} style={{ textAlign: "center" }}>
                    <strong>Image </strong>
                    <Col style={{ marginTop: "8px" }}>
                      <Image
                        src={verticalData?.image}
                        alt="image"
                        width={60}
                        height={60}
                        preview={{
                          mask: "view",
                        }}
                      />
                    </Col>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </WithAuth>
      </MainLayout>
    </>
  );
};

export default VerticalDetails;
