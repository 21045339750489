import React from "react";
import Features from "../../Modules/Features";

const FeaturesScreen = () => {
  return (
    <>
      <Features />
    </>
  );
};

export default FeaturesScreen;
