import React from "react";
import OrderDetail from "../../Modules/OrderDetail";

const OrderDetailScreen = () => {
  return (
    <>
      <OrderDetail />
    </>
  );
};

export default OrderDetailScreen;
