import React, { useEffect, useState } from "react";
import moment from "moment";
import { Row, Col, Table, Card } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";
import { getApi } from "../../services/getApi";
import WithAuth from "../../Middleware/WithAuth";
import { logout } from "../../redux/actions/common";

const OrderDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [ordetDetailObj, setOrdetDetailObj] = useState(null);

  const phaseColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt && moment(createdAt).format("DD-MM-YYYY hh:mm:ss"),
    },
  ];

  const instalmentColumns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Installment",
      dataIndex: "instalment",
      key: "instalment",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (createdAt) =>
        createdAt && moment(createdAt).format("DD-MM-YYYY"),
    },
    {
      title: "Status",
      dataIndex: "is_paid",
      key: "is_paid",
      render: (e) =>
      e ? "Paid" : "Pending"
    },
  ];

  const addonColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (value, obj) => `${value} ${obj.duration_type}`,
    },
    {
      title: "Date Joined",
      dataIndex: "date_joined",
      key: "date_joined",
      render: (date_joined) =>
        moment(date_joined).format("DD-MM-YYYY hh:mm:ss"),
    },
  ];

  const templateColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt && moment(createdAt).format("DD-MM-YYYY hh:mm:ss"),
    },
  ];

  const featureColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Customization Cost",
      dataIndex: "customization_cost",
      key: "customization_cost",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (value, obj) => `${value} ${obj.duration_type}`,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt && moment(createdAt).format("DD-MM-YYYY hh:mm:ss"),
    },
  ];

  useEffect(() => {
    getApi({
      cbSuccess: (response) => {
        if (response) {
          response['installments'] = [...response?.order_object?.installments] || []
          setOrdetDetailObj(response);
        }
        setIsLoading(false);
      },
      cbFailure: (err) => {
        setIsLoading(false);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: `orders/${id}`,
      value: null,
    });
  }, []);

  console.log("ordetDetailObj", ordetDetailObj);

  return (
    <>
      <MainLayout>
        <WithAuth>
          <Row>
            <Col xs={24} className="mt-20">
              <Card title="Orders">
                {isLoading && "Please wait..."}
                {!isLoading && ordetDetailObj ? (
                  <>
                    <Card title="Order Info">
                      <Row gutter={8}>
                        <Col >
                          <Card>
                            <div>Project Title</div>
                            {ordetDetailObj.order_object.project_title}
                          </Card>
                        </Col>
                        <Col >
                          <Card>
                            <div>Client Name</div>
                            {ordetDetailObj.user?.name}
                          </Card>
                        </Col>
                        <Col >
                          <Card>
                            <div>Client Email</div>
                            {ordetDetailObj.user.email}
                          </Card>
                        </Col>
                        <Col >
                          <Card>
                            <div>Status</div>
                            {ordetDetailObj.status}
                          </Card>
                        </Col>
                        <Col >
                          <Card>
                            <div>Total Cost</div>
                            {ordetDetailObj.total_amount}
                          </Card>
                        </Col>
                        <Col >
                          <Card>
                            <div>Customization Cost</div>
                            {ordetDetailObj.order_object.custom_cost}
                          </Card>
                        </Col>
                        <Col >
                          <Card>
                            <div>Total Duration</div>
                            {ordetDetailObj.order_object.total_duration}
                          </Card>
                        </Col>
                        <Col >
                          <Card>
                            <div>Payment Status</div>
                            {ordetDetailObj.order_object.payment_status}
                          </Card>
                        </Col>
                      </Row>
                    </Card>

                    <Card title="Instalment">
                      <Table
                        columns={instalmentColumns}
                        dataSource={ordetDetailObj.installments}
                        loading={isLoading}
                      />
                    </Card>

                    <Card title="Phases">
                      <Table
                        columns={phaseColumns}
                        dataSource={ordetDetailObj.phases}
                        loading={isLoading}
                      />
                    </Card>

                    <Card title="Addons">
                      <Table
                        columns={addonColumns}
                        dataSource={ordetDetailObj.addons}
                        loading={isLoading}
                      />
                    </Card>

                    <Card title="Templates">
                      <Table
                        columns={templateColumns}
                        dataSource={ordetDetailObj.templates}
                        loading={isLoading}
                      />
                    </Card>

                    <Card title="Features">
                      <Table
                        columns={featureColumns}
                        dataSource={ordetDetailObj.features}
                        loading={isLoading}
                      />
                    </Card>
                  </>
                ) : null}
              </Card>
            </Col>
          </Row>
        </WithAuth>
      </MainLayout>
    </>
  );
};

export default OrderDetail;
