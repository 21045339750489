import React from "react";
import Dashboard from "../../Modules/Dashboard";
//import withAuth from "../../middleware/index";

const DashboardScreen = () => {
  return (
    <>
      {/* <withAuth> */}
      <Dashboard />
      {/* </withAuth> */}
    </>
  );
};

export default DashboardScreen;
