import React from "react";
import UserDetail from "../../../Modules/Admin/UserDetail";

const UserDetailScreen = () => {
  return (
    <>
      {/* <withAuth> */}
      <UserDetail />
      {/* </withAuth> */}
    </>
  );
};

export default UserDetailScreen;
