import React from "react";
import Categories from "../../Modules/Categories";

const CategoriesScreen = () => {
  return (
    <>
      <Categories />
    </>
  );
};

export default CategoriesScreen;
