import React from "react";

const LikeSetting = ({ onSaveSetting }) => {
  const save = () => {
    onSaveSetting();
  };

  return <>setting</>;
};

export default LikeSetting;
