import React from "react";
import Posts from "../../Modules/Posts";

const PostsScreen = () => {
  return (
    <>
      <Posts />
    </>
  );
};

export default PostsScreen;
