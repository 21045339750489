import React from "react";
import DMs from "../../Modules/DMs";

const DMsScreen = () => {
  return (
    <>
      <DMs />
    </>
  );
};

export default DMsScreen;
