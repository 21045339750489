import React from "react";
import Users from "../../Modules/Users";

const UsersScreen = () => {
  return (
    <>
      <Users />
    </>
  );
};

export default UsersScreen;
