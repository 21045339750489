import React from "react";
import Phases from "../../Modules/Phases";

const PhasesScreen = () => {
  return (
    <>
      <Phases />
    </>
  );
};

export default PhasesScreen;
