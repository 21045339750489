import React from "react";
import Addons from "../../Modules/Addons";

const AddonsScreen = () => {
  return (
    <>
      <Addons />
    </>
  );
};

export default AddonsScreen;
