import React from "react";
import PaymentMethods from "../../Modules/PaymentMethods";

const PaymentMethodsScreen = () => {
  return (
    <>
      <PaymentMethods />
    </>
  );
};

export default PaymentMethodsScreen;
