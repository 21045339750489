import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Button, Row, Col, Table, Card, Input, Tabs, Form, Image } from "antd";
import {
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import MainLayout from "../../../Layout/MainLayout";
import { getApi } from "../../../services/getApi";
import { logout } from "../../../redux/actions/common";

const TemplateDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { paramId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [templatesData, setTemplatesData] = useState(null);

  useEffect(() => {
    getApi({
      cbSuccess: (response) => {
        if (response) {
          // console.log("response w", response);
          setTemplatesData(response);
        }
        setIsLoading(false);
      },
      cbFailure: (err) => {
        setIsLoading(false);
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: `templates/${paramId}`,
      value: null,
    });
  }, []);

  const renderHeader = () => {
    return (
      <>
        {" "}
        <Button
          type="primary"
          style={{
            background: "transparent",
            color: "white",
            marginRight: "8px",
          }}
          onClick={() => [history.goBack()]}
        >
          <ArrowLeftOutlined />
        </Button>
        Templates Detail
      </>
    );
  };

  return (
    <>
      <MainLayout>
        {/* <WithAuth> */}
        <Row>
          <Col xs={24} className="mt-20">
            <Card title={renderHeader()}>
              <Row gutter={8}>
                <Col xs={14}>
                  <Col>
                    <strong>Templates Name: </strong>
                    {templatesData?.name}
                  </Col>
                  <Col>
                    <strong>Price: </strong>
                    {templatesData?.price}
                  </Col>
                  <Col>
                    <strong>Description: </strong>
                    {templatesData?.description}
                  </Col>
                  <Col>
                    <strong>Platforms: </strong>
                    {templatesData?.platforms?.name}
                  </Col>
                  <Col>
                    <strong>Verticals: </strong>
                    {templatesData?.vertical.name}
                  </Col>

                  <Col>
                    <strong>Phases: </strong>
                    {templatesData?.phases.name}
                  </Col>
                  <Col>
                    <strong>Features: </strong>
                    {templatesData?.features &&
                      templatesData.features.map((e) => e.name + ", ")}
                  </Col>
                  <Col>
                    <strong>Created At: </strong>
                    {moment(templatesData?.createdAt).format(
                      "DD-MM-YYYY hh:mm:ss"
                    )}
                  </Col>
                </Col>
                <Col xs={10} style={{ textAlign: "center" }}>
                  <strong>Image </strong>
                  <Col style={{ marginTop: "8px" }}>
                    {templatesData?.image.map((e) => {
                      if (e)
                        return (
                          <Image
                            src={e.url}
                            alt="image"
                            width={60}
                            height={60}
                            preview={{
                              mask: "view",
                            }}
                          />
                        );
                    })}
                  </Col>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* </WithAuth> */}
      </MainLayout>
    </>
  );
};

export default TemplateDetails;
