import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import {
  Button,
  Row,
  Col,
  Table,
  Card,
  Input,
  Modal,
  Form,
  InputNumber,
  Select,
  Upload,
  message,
  Image,
} from "antd";
import type { UploadProps } from "antd";
import { useForm } from "antd/lib/form/Form";
import { EyeOutlined, UploadOutlined, ZoomInOutlined } from "@ant-design/icons";
import { deleteApi } from "../../services/deleteApi";
import { patchApi } from "../../services/patchApi";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";
import { getApi } from "../../services/getApi";
import { postApi } from "../../services/postApi";
import WithAuth from "../../Middleware/WithAuth";
import { logout } from "../../redux/actions/common";
import { showMessage } from "../../util/showMessage";

const { TextArea } = Input;

const Features = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allRecords, setAllRecords] = useState([]);
  const [categories, setCategories] = useState([]);
  const recordsRef = useRef(allRecords);
  const [newRecordAdded, setNewRecordAdded] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm();

  const [imageState, setImageState] = useState([]);
  const [imageShowOnEdit, setimageShowOnEdit] = useState({
    mobileImages: [],
    disktopImages: [],
  });
  const [mobileImages, setMobileImages] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [updateState, setUpdateState] = useState({
    name: "",
    price: "",
    platforms_id: {},
    phases_id: {},
    addons_id: {},
    customization_cost: "",
    vertical_id: {},
    features: [],
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Customization Cost",
      dataIndex: "customization_cost",
      key: "customization_cost",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (value, obj) => `${value} ${obj.duration_type}`,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt && moment(createdAt).format("DD-MM-YYYY hh:mm:ss"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <>
          <Button
            title="View"
            icon={<EyeOutlined />}
            onClick={() => history.push(`/admin/feature-detail/${id}`)}
          />
          <Button
            type="primary"
            onClick={(e) => {
              const searching = allRecords[ObjectSearchForUpdate(id)];
              setUpdateState(searching);
              let selectedCategories = {};
              for (let i = 0; i < categories.length; ++i) {
                if (categories[i].value === searching["category_id"]) {
                  selectedCategories = {
                    label: categories[i].label,
                    value: categories[i].value,
                  };
                }
              }
              form.setFieldsValue({
                name: searching["name"],
                price: searching["price"],
                cost: searching["cost"],
                duration: searching["duration"],
                duration_type: searching["duration_type"],
                description: searching["description"],
                category_id: selectedCategories,
                customization_cost: searching["customization_cost"],
              });
              setimageShowOnEdit({
                mobileImages: searching["images"].filter((e) => e.is_mobile),
                disktopImages: searching["images"].filter((e) => !e.is_mobile),
              });
              console.log("check imageState", searching);
              setIsModalOpen(true);
            }}
          >
            Edit
          </Button>
          <span style={{ paddingLeft: "10px" }}>
            <Button
              type="primary"
              onClick={() => {
                setDeleteId(id);
                setIsDeleteModalOpen(true);
              }}
            >
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const ObjectSearchForUpdate = (id) => {
    for (let i = 0; i < allRecords?.length; ++i) {
      if (allRecords[i]["id"] === id) {
        return i;
      }
    }
    return;
  };
  useEffect(() => {
    getApi({
      cbSuccess: (response) => {
        if (response) {
          setAllRecords(response);
          recordsRef.current = response;
        }
        setIsLoading(false);
      },
      cbFailure: (err) => {
        setIsLoading(false);
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: "features",
      value: null,
    });

    getApi({
      cbSuccess: (response) => {
        if (response) {
          const compile = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            compile.push({
              label: element.name,
              value: element.id,
            });
          }
          setCategories(compile);
        }
        setIsLoading(false);
      },
      cbFailure: (err) => {
        setIsLoading(false);
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: "categories",
      value: null,
    });
  }, [newRecordAdded]);

  const onDelete = (id) => {
    setLoading(true);
    deleteApi({
      cbSuccess: (response) => {
        setLoading(false);
        showMessage("Record delete");
        setNewRecordAdded(!newRecordAdded);
        setIsDeleteModalOpen(false);
      },
      cbFailure: (err) => {
        setLoading(false);
        showMessage(err?.data?.detail, true);
      },
      url: `features/${id}`,
      guarded: false,
      value: id,
    });
  };

  const onSearch = (value) => {
    const oldList = recordsRef.current;
    if (value.target.value.length > 0) {
      var filteredArray = allRecords.filter(function (itm) {
        return (
          itm.cost.toString().indexOf(value.target.value.toString()) >= 0 ||
          itm.name.indexOf(value.target.value) >= 0 ||
          itm.description.indexOf(value.target.value) >= 0
        );
      });
      setAllRecords(filteredArray);
    } else {
      setAllRecords(oldList);
    }
  };
  const UpdateState = (values) => {
    setLoading(true);
    patchApi({
      cbSuccess: (response) => {
        setLoading(false);
        showMessage("Record created");
        setIsModalOpen(false);
        setNewRecordAdded(!newRecordAdded);
        setUpdateState({});
      },
      cbFailure: (err) => {
        setLoading(false);
        showMessage(err?.data?.detail, true);
      },
      url: `features/${updateState.id}`,
      guarded: false,
      value: values,
    });
    form.setFieldsValue({
      name: "",
      price: "",
      cost: "",
      duration: "",
      duration_type: "",
      customization_cost: "",
      description: "",
      category_id: {},
    });
    setimageShowOnEdit({ disktopImages: [], mobileImages: [] });
    setUpdateState({});
  };

  const onFinish = (values) => {
    values.images = imageState;
    values.mobileImages = mobileImages;

    if (updateState?.name) {
      UpdateState(values);
      return;
    }
    setLoading(true);
    postApi({
      cbSuccess: (response) => {
        setLoading(false);
        showMessage("Record created");
        setIsModalOpen(false);
        setNewRecordAdded(!newRecordAdded);
      },
      cbFailure: (err) => {
        setLoading(false);
        showMessage(err?.data?.detail, true);
      },
      url: "features",
      guarded: false,
      value: values,
    });
    form.setFieldsValue({
      name: "",
      price: "",
      cost: "",
      duration: "",
      duration_type: "",
      customization_cost: "",
      description: "",
      category_id: {},
    });
    setimageShowOnEdit({ disktopImages: [], mobileImages: [] });
  };

  const props: UploadProps = {
    name: "file",
    action: "https://builderapi.dfysaas.com/image-upload/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        const images = imageState;

        images.push(info.file.response.url);
        setImageState(images);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsMobile: UploadProps = {
    name: "file",
    action: "https://builderapi.dfysaas.com/image-upload/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        const images = mobileImages;
        images.push(info.file.response.url);
        setMobileImages(images);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <MainLayout>
        <WithAuth>
          <Modal
            title="Confirm"
            open={isDeleteModalOpen}
            footer={null}
            onCancel={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <h5>Are you sure you want to delete?</h5>
              </div>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => onDelete(deleteId)}
                  loading={loading}
                >
                  Confirm
                </Button>
              </div>
              <div className="mt-20">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={(_) => setIsDeleteModalOpen(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            title="Add Feature"
            open={isModalOpen}
            footer={null}
            onCancel={() => {
              form.setFieldsValue({
                name: "",
                price: "",
                cost: "",
                duration: "",
                duration_type: "",
                customization_cost: "",
                description: "",
                category_id: {},
              });
              setimageShowOnEdit({ disktopImages: [], mobileImages: [] });
              setUpdateState({});
              setIsModalOpen(false);
            }}
          >
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Cost" name="cost" rules={[{ required: true }]}>
                <InputNumber addonAfter="$" />
              </Form.Item>
              <Form.Item
                label="Customization Cost"
                name="customization_cost"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Duration"
                name="duration"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Duration Type"
                name="duration_type"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: "100%" }}
                  options={[
                    { value: "hours", label: "Hours" },
                    { value: "days", label: "Days" },
                    { value: "weeks", label: "Weeks" },
                    { value: "months", label: "Months" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Categories"
                name="category_id"
                rules={[{ required: true }]}
              >
                <Select
                  size={"middle"}
                  placeholder="Select Category"
                  style={{ width: "100%" }}
                  options={categories}
                />
              </Form.Item>

              <h3>Images for mobile</h3>
              <Row>
                <Col style={{ marginRight: "20px" }}>
                  <Upload {...propsMobile}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Col>
                {imageShowOnEdit.mobileImages.length > 0 && (
                  <Image
                    src={imageShowOnEdit.mobileImages[0].url}
                    alt="image"
                    width={50}
                    height={50}
                    preview={{
                      mask: <ZoomInOutlined />,
                    }}
                  />
                )}
              </Row>

              <h3>Images for desktop</h3>
              <Row>
                <Col style={{ marginRight: "20px" }}>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Col>
                {imageShowOnEdit.disktopImages.length > 0 && (
                  <Image
                    src={imageShowOnEdit.disktopImages[0].url}
                    alt="image"
                    width={50}
                    height={50}
                    preview={{
                      mask: <ZoomInOutlined />,
                    }}
                  />
                )}
              </Row>

              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true }]}
              >
                <TextArea rows={4} placeholder="Description" />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          <Row>
            <Col xs={24} className="mt-20">
              <Card
                title="Features"
                extra={
                  <Button onClick={() => setIsModalOpen(true)}>
                    Add Feature
                  </Button>
                }
              >
                <Input
                  placeholder="search..."
                  onChange={onSearch}
                  style={{
                    marginBottom: 10,
                  }}
                />
                <Table
                  columns={columns}
                  dataSource={allRecords}
                  loading={isLoading}
                />
              </Card>
            </Col>
          </Row>
        </WithAuth>
      </MainLayout>
    </>
  );
};

export default Features;
