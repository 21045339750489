import React from "react";
import Orders from "../../Modules/Orders";

const OrdersScreen = () => {
  return (
    <>
      <Orders />
    </>
  );
};

export default OrdersScreen;
