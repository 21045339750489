import React from "react";
import Likes from "../../Modules/Likes";

const LikesScreen = () => {
  return (
    <>
      <Likes />
    </>
  );
};

export default LikesScreen;
