import React from "react";
import Platforms from "../../Modules/Platforms";

const PlatformsScreen = () => {
  return (
    <>
      <Platforms />
    </>
  );
};

export default PlatformsScreen;
