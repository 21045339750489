import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import {
  Button,
  Row,
  Col,
  Table,
  Card,
  Input,
  Modal,
  Form,
  Select,
  Upload,
  message,
  Image,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import type { UploadProps } from "antd";
import { EyeOutlined, UploadOutlined, ZoomInOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";
import { getApi } from "../../services/getApi";
import { postApi } from "../../services/postApi";
import { deleteApi } from "../../services/deleteApi";
import WithAuth from "../../Middleware/WithAuth";
import { logout } from "../../redux/actions/common";
import { showMessage } from "../../util/showMessage";
import { patchApi } from "../../services/patchApi";
import ImageUpload from "../../Components/ImageUpload";
import TextArea from "antd/lib/input/TextArea";

const Templates = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allRecords, setAllRecords] = useState([]);
  const [features, setFeatures] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [platforms, setPlaforms] = useState([]);
  const [addons, setAddons] = useState([]);
  const [phases, setPhases] = useState([]);
  const recordsRef = useRef(allRecords);
  const [newRecordAdded, setNewRecordAdded] = useState(false);
  const [image, setImage] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm();
  const [imageState, setImageState] = useState([]);
  const [logoImageName, setLogoImageName] = useState("");
  const [imageShowOnEdit, setimageShowOnEdit] = useState({
    mobileImages: [],
    disktopImages: [],
  });
  const [mobileImages, setMobileImages] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [updateState, setUpdateState] = useState({
    name: "",
    price: "",
    platforms_id: {},
    phases_id: {},
    addons_id: {},
    vertical_id: {},
    features: [],
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt && moment(createdAt).format("DD-MM-YYYY hh:mm:ss"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <>
          <Button
            title="View"
            icon={<EyeOutlined />}
            onClick={() => history.push(`/admin/template-detail/${id}`)}
          />
          <Button
            type="primary"
            onClick={(e) => {
              const searching = allRecords[ObjectSearchForUpdate(id)];
              let selectedPlatforms = {};
              let selectedPhases = {};
              let selectedAddons = {};
              let selectedVerticals = {};
              let selectedFeatures = [];
              setUpdateState(searching);
              for (let i = 0; i < platforms.length; ++i) {
                if (platforms[i].value === searching.platforms_id) {
                  selectedPlatforms = {
                    label: platforms[i].label,
                    value: platforms[i].value,
                  };
                }
              }
              for (let i = 0; i < phases.length; ++i) {
                if (phases[i].value === searching.phases_id) {
                  selectedPhases = {
                    label: phases[i].label,
                    value: phases[i].value,
                  };
                }
              }
              for (let i = 0; i < addons.length; ++i) {
                if (addons[i].value === searching.addons_id) {
                  selectedAddons = {
                    label: addons[i].label,
                    value: addons[i].value,
                  };
                }
              }
              for (let i = 0; i < addons.length; ++i) {
                if (addons[i].value === searching.addons_id) {
                  selectedAddons = {
                    label: addons[i].label,
                    value: addons[i].value,
                  };
                }
              }
              for (let i = 0; i < verticals.length; ++i) {
                if (verticals[i].value === searching.vertical_id) {
                  selectedVerticals = {
                    label: verticals[i].label,
                    value: verticals[i].value,
                  };
                }
              }
              for (let i = 0; i < features.length; ++i) {
                for (let selectedCheckList of searching.features) {
                  if (features[i].value === selectedCheckList["id"]) {
                    selectedFeatures.push({
                      label: features[i].label,
                      value: features[i].value,
                    });
                  }
                }
              }
              console.log("check ", searching);
              form.setFieldsValue({
                name: searching["name"],
                price: searching["price"],
                description: searching["description"],
                platforms_id: selectedPlatforms,
                phases_id: selectedPhases,
                addons_id: selectedAddons,
                vertical_id: selectedVerticals,
                features: selectedFeatures,
              });
              setimageShowOnEdit({
                mobileImages: searching["image"].filter((e) => e.is_mobile),
                disktopImages: searching["image"].filter((e) => !e.is_mobile),
              });
              setImage(searching.logo);
              setIsModalOpen(true);
            }}
          >
            Edit
          </Button>
          <span style={{ paddingLeft: "10px" }}>
            <Button
              type="primary"
              onClick={() => {
                setDeleteId(id);
                setIsDeleteModalOpen(true);
              }}
            >
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const ObjectSearchForUpdate = (id) => {
    for (let i = 0; i < allRecords?.length; ++i) {
      if (allRecords[i]["id"] === id) {
        return i;
      }
    }
    return;
  };
  useEffect(() => {
    getApi({
      cbSuccess: (response) => {
        if (response) {
          setAllRecords(response);
          recordsRef.current = response;
        }
        setIsLoading(false);
      },
      cbFailure: (err) => {
        setIsLoading(false);
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: "templates",
      value: null,
    });

    getApi({
      cbSuccess: (response) => {
        if (response) {
          const compile = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            compile.push({
              label: element.name,
              value: element.id,
            });
          }
          setPlaforms(compile);
        }
      },
      cbFailure: (err) => {
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: "platforms",
      value: null,
    });

    getApi({
      cbSuccess: (response) => {
        if (response) {
          const compile = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            compile.push({
              label: element.name,
              value: element.id,
            });
          }

          setAddons(compile);
        }
      },
      cbFailure: (err) => {
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: "addons",
      value: null,
    });

    getApi({
      cbSuccess: (response) => {
        if (response) {
          const compile = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            compile.push({
              label: element.name,
              value: element.id,
            });
          }

          setPhases(compile);
        }
      },
      cbFailure: (err) => {
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: "phases",
      value: null,
    });

    getApi({
      cbSuccess: (response) => {
        if (response) {
          const compile = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            compile.push({
              label: element.name,
              value: element.id,
            });
          }

          setFeatures(compile);
        }
      },
      cbFailure: (err) => {
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: "features",
      value: null,
    });

    getApi({
      cbSuccess: (response) => {
        if (response) {
          const compile = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            compile.push({
              label: element.name,
              value: element.id,
            });
          }

          setVerticals(compile);
        }
      },
      cbFailure: (err) => {
        console.log("err", err);
        if (err.status === 401) {
          logout(dispatch);
          history.push("/login");
        }
      },
      url: "verticals",
      value: null,
    });
  }, [newRecordAdded, mobileImages]);

  const onDelete = (id) => {
    setLoading(true);
    deleteApi({
      cbSuccess: (response) => {
        setLoading(false);
        showMessage("Record delete");
        setNewRecordAdded(!newRecordAdded);
        setIsDeleteModalOpen(false);
      },
      cbFailure: (err) => {
        setLoading(false);
        showMessage(err?.data?.detail, true);
      },
      url: `templates/${id}`,
      guarded: false,
      value: id,
    });
  };
  const onSearch = (value) => {
    const oldList = recordsRef.current;
    if (value.target.value.length > 0) {
      var filteredArray = allRecords.filter(function (itm) {
        return itm.name.indexOf(value.target.value) >= 0;
      });
      setAllRecords(filteredArray);
    } else {
      setAllRecords(oldList);
    }
  };
  const UpdateState = (values) => {
    if (!image) {
      showMessage("Insert an Image of logo", true);
      return;
    }
    values.logo = image;
    if (imageState) values.images = imageState;
    if (mobileImages) values.mobileImages = mobileImages;

    setLoading(true);
    patchApi({
      cbSuccess: (response) => {
        setLoading(false);
        showMessage("Record created");
        setIsModalOpen(false);
        setNewRecordAdded(!newRecordAdded);
        setUpdateState({});
      },
      cbFailure: (err) => {
        setLoading(false);
        showMessage(err?.data?.detail, true);
      },
      url: `templates/${updateState.id}`,
      guarded: false,
      value: values,
    });
    form.setFieldsValue({
      name: "",
      description: "",
      price: "",
      platforms_id: "",
      phases_id: "",
      addons_id: "",
      vertical_id: "",
      features: [],
    });
    setimageShowOnEdit({ disktopImages: [], mobileImages: [] });
    setImage([]);
    setUpdateState({});
    setImageState([]);
    setMobileImages([]);
    setLogoImageName("");
  };
  const onFinish = async (values) => {
    console.log("VALUES  test: ", values);
    if (updateState?.name) {
      UpdateState(values);
      return;
    }
    if (!image) {
      showMessage("Insert an Logo", true);
      return;
    }
    values.images = imageState;
    values.mobileImages = mobileImages;
    values.logo = image;

    setLoading(true);
    postApi({
      cbSuccess: (response) => {
        setLoading(false);
        showMessage("Record created");
        setIsModalOpen(false);
        setNewRecordAdded(!newRecordAdded);
      },
      cbFailure: (err) => {
        setLoading(false);
        showMessage(err?.data?.detail, true);
      },
      url: "templates",
      guarded: false,
      value: values,
    });
    form.setFieldsValue({
      name: "",
      price: "",
      platforms_id: "",
      description: "",
      phases_id: "",
      addons_id: "",
      vertical_id: "",
      features: [],
    });
    setimageShowOnEdit({ disktopImages: [], mobileImages: [] });
    setImageState([]);
    setMobileImages([]);
    setLogoImageName("");
  };

  const props: UploadProps = {
    name: "file",
    action: "https://builderapi.dfysaas.com/image-upload/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        const images = imageState;

        images.push(info.file.response.url);
        setImageState(images);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: false, // Set showUploadList to false to hide the flat list
  };

  const propsMobile: UploadProps = {
    name: "file",
    action: "https://builderapi.dfysaas.com/image-upload/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        const images = mobileImages;
        images.push(info.file.response.url);
        setMobileImages(images);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: false, // Set showUploadList to false to hide the flat list
  };
  console.log(" imageShowOnEdit ", imageShowOnEdit);
  return (
    <>
      <MainLayout>
        <WithAuth>
          <Modal
            title="Confirm"
            open={isDeleteModalOpen}
            footer={null}
            onCancel={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <h5>Are you sure you want to delete?</h5>
              </div>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => onDelete(deleteId)}
                  loading={loading}
                >
                  Confirm
                </Button>
              </div>
              <div className="mt-20">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={(_) => setIsDeleteModalOpen(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            title="Add Template"
            open={isModalOpen}
            footer={null}
            onCancel={() => {
              form.setFieldsValue({
                name: "",
                price: "",
                platforms_id: "",
                phases_id: "",
                addons_id: "",
                vertical_id: "",
                features: [],
              });
              setimageShowOnEdit({ disktopImages: [], mobileImages: [] });
              setUpdateState({});
              setIsModalOpen(false);
              setImageState([]);
              setMobileImages([]);
              setImage([]);
            }}
          >
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              {/* customization_cost */}

              <Form.Item
                label="Platforms"
                name="platforms_id"
                rules={[{ required: true }]}
              >
                <Select
                  size={"middle"}
                  placeholder="Select Platforms"
                  style={{ width: "100%" }}
                  options={platforms}
                />
              </Form.Item>
              <Form.Item
                label="Addons"
                name="addons_id"
                rules={[{ required: true }]}
              >
                <Select
                  size={"middle"}
                  placeholder="Select Addons"
                  style={{ width: "100%" }}
                  options={addons}
                />
              </Form.Item>
              <Form.Item
                label="Phases"
                name="phases_id"
                rules={[{ required: true }]}
              >
                <Select
                  size={"middle"}
                  placeholder="Select Phases"
                  style={{ width: "100%" }}
                  options={phases}
                />
              </Form.Item>

              <Form.Item
                label="Vertical"
                name="vertical_id"
                rules={[{ required: true }]}
              >
                <Select
                  size={"middle"}
                  placeholder="Select Vertical"
                  style={{ width: "100%" }}
                  options={verticals}
                />
              </Form.Item>

              <Form.Item
                label="Features"
                name="features"
                rules={[{ required: true }]}
              >
                <Select
                  mode="tags"
                  size={"middle"}
                  placeholder="Select Feature"
                  style={{ width: "100%" }}
                  options={features}
                />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true }]}
              >
                <TextArea />
              </Form.Item>

              <h3>Images for mobile</h3>
              <div>
                <Row>
                  <Col style={{ marginRight: "20px" }}>
                    {" "}
                    <Upload {...propsMobile}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </Col>
                  {imageShowOnEdit.mobileImages.length > 0 ? (
                    <Image
                      src={imageShowOnEdit.mobileImages[0]?.url}
                      alt="image"
                      width={50}
                      height={50}
                      preview={{
                        mask: <ZoomInOutlined />,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Row>
              </div>

              <h3>Images for desktop</h3>
              <Row>
                <Col style={{ marginRight: "20px" }}>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Col>
                {imageShowOnEdit.disktopImages.length > 0 ? (
                  <Image
                    src={imageShowOnEdit.mobileImages[0]?.url}
                    alt="image"
                    width={50}
                    height={50}
                    preview={{
                      mask: <ZoomInOutlined />,
                    }}
                  />
                ) : (
                  ""
                )}
              </Row>
              <h3>Image for Logo</h3>

              <Form.Item>
                <Row>
                  <Col style={{ marginRight: "20px" }}>
                    <ImageUpload
                      setImage={setImage}
                      image={logoImageName}
                      setLogoImageName={setLogoImageName}
                    />
                  </Col>
                  {image ? (
                    <Image
                      src={image}
                      alt="image"
                      width={50}
                      height={50}
                      preview={{
                        mask: <ZoomInOutlined />,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Row>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          <Row>
            <Col xs={24} className="mt-20">
              <Card
                title="Templates"
                extra={
                  <Button onClick={() => setIsModalOpen(true)}>
                    Add Template
                  </Button>
                }
              >
                <Input
                  placeholder="search..."
                  onChange={onSearch}
                  style={{
                    marginBottom: 10,
                  }}
                />
                <Table
                  columns={columns}
                  dataSource={allRecords}
                  loading={isLoading}
                />
              </Card>
            </Col>
          </Row>
        </WithAuth>
      </MainLayout>
    </>
  );
};

export default Templates;
