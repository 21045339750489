import React from "react";
import Comments from "../../Modules/Comments";

const CommentsScreen = () => {
  return (
    <>
      <Comments />
    </>
  );
};

export default CommentsScreen;
