import React from "react";
import Templates from "../../Modules/Templates";

const TemplatesScreen = () => {
  return (
    <>
      <Templates />
    </>
  );
};

export default TemplatesScreen;
